import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { STRING_LIST_DUPLICATES_VALIDATOR } from '../../custom-validators';
import { TranslationService } from '../../services/translation.service';
import { ImmediateMatcher } from '../../services/user-profile.service';
import { Utilities } from '../../utilities';
import { PortalFormGroup } from '../../danger-zone/form-group-override';
import { PortalFormControl } from '../../danger-zone/form-control-override';
import { PortalFormArray } from '../../danger-zone/form-array-override';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'portal-invoice-email-edit-form',
  templateUrl: './invoice-email-edit-form.component.html',
  styleUrls: ['./invoice-email-edit-form.component.scss'],
})
export class InvoiceEMailEditFormComponent implements OnInit, OnDestroy, OnChanges {
  public emailFormArray: PortalFormArray;
  public parentForm: PortalFormGroup;
  @Input() public isInDialog = false;
  @Input() public isIntegrated = false;
  @Input() public isDisabled = false;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public immediateMatcher = new ImmediateMatcher();

  constructor(
    public translationService: TranslationService,
    public userService: UserService
  ) {
    this.emailFormArray = new PortalFormArray([], [STRING_LIST_DUPLICATES_VALIDATOR]);

    this.userService.customer$
      .pipe(
        takeUntil(this._destroyEmitter),
        filter((customerData) => !!customerData)
      )
      .subscribe((customerData) => {
        customerData.CustomerInvoiceEMailAddresses.forEach((emailAddress) =>
          this.emailFormArray.push(new PortalFormControl(this._destroyEmitter, emailAddress, [Utilities.emailValidator]))
        );

        this.emailFormArray.reset(customerData.CustomerInvoiceEMailAddresses);

        // Add initial input if no email exists
        if (this.emailFormArray.controls.length <= 0) {
          this.addInputField();
        }
      });

    this.parentForm = new PortalFormGroup({
      CustomerName1: new PortalFormControl(this._destroyEmitter),
      CustomerName2: new PortalFormControl(this._destroyEmitter),
      CustomerStreet: new PortalFormControl(this._destroyEmitter),
      CustomerZipCodeNo: new PortalFormControl(this._destroyEmitter),
      CustomerTown: new PortalFormControl(this._destroyEmitter),
      CustomerCountryId: new PortalFormControl(this._destroyEmitter),
      CustomerPhone: new PortalFormControl(this._destroyEmitter),
      CustomerFax: new PortalFormControl(this._destroyEmitter),
      CustomerVatNo: new PortalFormControl(this._destroyEmitter),
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('isDisabled' in changes) {
      if (changes.isDisabled.currentValue) {
        this.parentForm.disable();
      } else {
        this.parentForm.enable();
      }
    }
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  public ngOnInit(): void {
    this.parentForm.reset(this.userService.customer$.value);
    this.parentForm.addControl('CustomerInvoiceEMailAddresses', this.emailFormArray);
    if (this.isDisabled) {
      this.parentForm.disable();
    }
  }

  public addInputField(emailAddress?: string): void {
    this.emailFormArray.push(new PortalFormControl(this._destroyEmitter, emailAddress || '', [Utilities.emailValidator]));
    this.emailFormArray.controls[this.emailFormArray.length - 1].markAsPristine();
  }

  public removeEMailFromList(index: number): void {
    if (index === 0 && this.emailFormArray.length === 1) {
      this.emailFormArray.controls[index].setValue('');
      this.emailFormArray.controls[index].markAsDirty();
    } else {
      this.emailFormArray.removeAt(index);
      this.emailFormArray.markAsDirty();
    }

    if (JSON.stringify(this.emailFormArray.initialValue) === JSON.stringify(this.emailFormArray.value)) {
      this.emailFormArray.markAsPristine();
    }
  }

  public hasEmptyInput(): boolean {
    return this.emailFormArray.getRawValue().some((emailAddress) => !emailAddress);
  }
}
