import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { IUser, IUserDataControlConfig, UserService } from '../services/user.service';
import { ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { TranslationService } from '../services/translation.service';
import { DropdownValuesService } from '../services/dropdown-values.service';
import { Utilities } from '../utilities';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Moment } from 'moment';
import { PortalFormGroup } from '../danger-zone/form-group-override';
import { PortalFormControl } from '../danger-zone/form-control-override';
import { IAdvertisingInterest } from '../interfaces/dropdown-values.interface';

@Component({
  selector: 'portal-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  styleUrls: ['./user-edit-form.component.scss'],
})
export class UserEditFormComponent implements OnInit, OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public editUserControlConfigs: IUserDataControlConfig[] = [];
  public editUserForm: PortalFormGroup = new PortalFormGroup({});
  public startDate: Moment = moment.utc('1968', 'YYYY');
  @Input() public editPersonalDataIsForbidden = false;
  public selectedAdvertisingInterests: IAdvertisingInterest[] = [];

  constructor(
    public translationService: TranslationService,
    public userService: UserService,
    public appService: AppService,
    public dropdownValuesService: DropdownValuesService
  ) {
    this.editUserControlConfigs = this.userService.USER_DATA_CONTROL_CONFIGS.filter((e) => e.type !== 'email');
    this.editUserControlConfigs.push({
      name: 'UserAdvertisingInterestUUIDs',
      translationKey: 'AdvertisingInterest',
      type: 'chip-autocomplete',
    });

    for (const controlConfig of this.editUserControlConfigs) {
      this.editUserForm.addControl(controlConfig.name, new PortalFormControl(this._destroyEmitter));

      const validators: ValidatorFn[] = [];

      if (controlConfig.maxLength) {
        validators.push(Validators.maxLength(controlConfig.maxLength));
      }
      if (controlConfig.minLength) {
        validators.push(Validators.minLength(controlConfig.minLength));
      }
      if (controlConfig.required) {
        validators.push(Validators.required);
      }
      if (controlConfig.type === 'email') {
        validators.push(Utilities.emailValidator);
      }
      if (controlConfig.type === 'select') {
        validators.push(Validators.pattern(/^[^0]$/));
      }

      this.editUserForm.controls[controlConfig.name].setValidators(validators);
      this.userService.currentUser$.pipe(filter((user) => !!user)).subscribe((user) => {
        this.editUserForm.reset(user);
      });
    }

    this.userService.currentUser$
      .pipe(
        takeUntil(this._destroyEmitter),
        tap((currentUser: IUser) => {
          if (currentUser.UserAdvertisingInterestUUIDs.length > 0) {
            this.selectedAdvertisingInterests = this.dropdownValuesService.dropdownValues$.value.AdvertisingInterests.filter((interest) =>
              currentUser.UserAdvertisingInterestUUIDs.includes(interest.AdvertisingInterestUUID)
            );
          }
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    if (this.editPersonalDataIsForbidden) {
      this.editUserForm.disable();
    }
  }

  ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  selectedAdvertisingInterestsChange(advertisingInterests: IAdvertisingInterest[]): void {
    this.editUserForm.controls['UserAdvertisingInterestUUIDs'].setValue(advertisingInterests.map((interest) => interest.AdvertisingInterestUUID));
    this.editUserForm.controls['UserAdvertisingInterestUUIDs'].markAsDirty();
  }
}
