import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserEditFormComponent } from './user-edit-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AdvertisingInterestsAutocompleteComponent } from './advertising-interests-autocomplete/advertising-interests-autocomplete.component';

@NgModule({
  declarations: [UserEditFormComponent],
  exports: [UserEditFormComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatInputModule,
    AdvertisingInterestsAutocompleteComponent,
  ],
})
export class UserEditFormModule {}
