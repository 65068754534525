<mat-form-field color="accent" floatLabel="auto">
  <mat-label>{{ translationService.translations.common.AdvertisingInterests }}</mat-label>
  <mat-chip-grid #chipGrid>
    @for (selectedAdvertisingInterest of selectedAdvertisingInterests; track $index) {
      <mat-chip-row (removed)="removeAdvertisingInterest(selectedAdvertisingInterest)" [removable]="true">
        {{ selectedAdvertisingInterest.AdvertisingInterestDescription[translationService.locale] }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    }
    <input
      #advertisingInterestInput
      (matChipInputTokenEnd)="selectAdvertisingInterestFromInput($event)"
      [formControl]="advertisingInterestsControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
      matInput
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAdvertisingInterestFromAutocomplete($event)">
    @for (filteredAdvertisingInterest of filteredAdvertisingInterests; track $index) {
      <mat-option [value]="filteredAdvertisingInterest.AdvertisingInterestUUID">
        <span>{{ filteredAdvertisingInterest.AdvertisingInterestDescription[translationService.locale] }}</span>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
