import { Component, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseDialogComponent } from '../../base-dialog/base-dialog.component';
import { CustomerService } from '../../services/customer.service';
import { ICustomer } from '../../services/user.service';
import { PortalFormGroup } from '../../danger-zone/form-group-override';
import { LoadingService } from '../../services/loading.service';
import { SettingsService } from '../../services/settings.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'portal-invoice-email-proclamation',
  templateUrl: './invoice-email-proclamation.component.html',
  styleUrls: ['./invoice-email-proclamation.component.scss'],
})
export class InvoiceEmailProclamationComponent implements OnDestroy {
  @Input() public hideDismissNotificationCheckbox = false;
  @ViewChild('baseDialogComponent') public baseDialog: BaseDialogComponent<InvoiceEmailProclamationComponent>;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public settingsService: SettingsService,
    public translationService: TranslationService,
    private _customerService: CustomerService,
    public loadingService: LoadingService
  ) {}

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  public saveEMailAddresses(customerEditForm: PortalFormGroup): void {
    this.loadingService.isLoading$.next(true);

    const customerFormValue: ICustomer = customerEditForm.value;

    if (customerFormValue.CustomerInvoiceEMailAddresses && customerFormValue.CustomerInvoiceEMailAddresses.length > 0) {
      customerFormValue.CustomerInvoiceEMailAddresses = customerFormValue.CustomerInvoiceEMailAddresses.filter((emailAddress) => !!emailAddress);
    }

    this._customerService
      .saveInvoiceEMailAddresses(customerFormValue)
      .pipe(
        takeUntil(this._destroyEmitter),
        finalize(() => {
          this.loadingService.isLoading$.next(false);
          this.baseDialog.dismiss(true);
        })
      )
      .subscribe();
  }
}
