<form class="email-list-form-group" [formGroup]="parentForm">
  <mat-list formArrayName="CustomerInvoiceEMailAddresses">
    @for (email of emailFormArray.controls; track email; let i = $index) {
      <mat-list-item class="email-list-item">
        <mat-form-field class="email-field">
          <mat-label>{{ translationService.translations.common.CustomerInvoiceEMailAddressLabel }}</mat-label>
          <input matInput #email maxlength="384" [formControlName]="i" autocomplete="off" [errorStateMatcher]="immediateMatcher" />
          <button
            (click)="removeEMailFromList(i)"
            [disabled]="isDisabled"
            [matTooltip]="translationService.translations.action.DeleteAdditionalEmailAddress.toString()"
            mat-icon-button
            matIconSuffix
          >
            <mat-icon [color]="isDisabled ? 'primary' : 'warn'">delete</mat-icon>
          </button>
          @if (email.hasError('duplicates') && !email.hasError('required') && !email.hasError('email')) {
            <mat-error>{{ translationService.translations.error.EMailIsDuplicate }}</mat-error>
          } @else if (email.hasError('required')) {
            <mat-error>{{ translationService.translations.error.FieldIsRequired }}</mat-error>
          } @else if (email.hasError('email')) {
            <mat-error>{{ translationService.translations.error.WrongEmailFormat }}</mat-error>
          }
        </mat-form-field>
      </mat-list-item>
    }
    <mat-list-item>
      @if (
        emailFormArray.length < 20 &&
        (emailFormArray.controls.length > 1 ||
          (emailFormArray.controls.length === 1 && emailFormArray.controls[0].value !== '' && emailFormArray.controls[0].valid))
      ) {
        <button
          mat-stroked-button
          class="add-additional-email-address"
          (click)="addInputField()"
          [disabled]="isDisabled || emailFormArray.invalid || hasEmptyInput()"
        >
          <mat-icon [svgIcon]="'enthus-add'"></mat-icon>&nbsp;{{ translationService.translations.action.AddAdditionalEmailAddress }}
        </button>
      }
    </mat-list-item>
  </mat-list>
</form>
