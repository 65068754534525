import { UntypedFormArray } from '@angular/forms';
import { PortalFormControl } from './form-control-override';

export class PortalFormArray extends UntypedFormArray {
  public initialValue: unknown;

  constructor(...props: ConstructorParameters<typeof UntypedFormArray>) {
    super(...props);

    this.initialValue = this.value;
  }

  override reset(...props): void {
    super.reset(...props);

    this.initialValue = this.value;

    this.controls.forEach((control) => {
      if (control instanceof PortalFormControl) {
        control.initialValue = control.value;
      }
    });
  }
}
