import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { filter, tap } from 'rxjs/operators';
import { PortalFormControl } from '../../danger-zone/form-control-override';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { IAdvertisingInterest } from '../../interfaces/dropdown-values.interface';

@Component({
  selector: 'portal-advertising-interests-autocomplete',
  templateUrl: './advertising-interests-autocomplete.component.html',
  styleUrls: ['./advertising-interests-autocomplete.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, ReactiveFormsModule, MatInputModule, MatAutocompleteModule, MatChipsModule, MatIconModule],
})
export class AdvertisingInterestsAutocompleteComponent implements OnInit, OnDestroy {
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  public advertisingInterestsControl: PortalFormControl = new PortalFormControl(this._destroyEmitter);
  @ViewChild('advertisingInterestInput') advertisingInterestInput: ElementRef<HTMLInputElement>;
  @Input() public selectedAdvertisingInterests: IAdvertisingInterest[];
  @Output() public selectedAdvertisingInterestsChange: EventEmitter<IAdvertisingInterest[]> = new EventEmitter<IAdvertisingInterest[]>();
  @Input() public autocompleteAdvertisingInterests: IAdvertisingInterest[];
  public filteredAdvertisingInterests: IAdvertisingInterest[];
  public separatorKeyCodes: number[] = [ENTER, COMMA];

  constructor(public translationService: TranslationService) {
    this.advertisingInterestsControl.valueChanges
      .pipe(
        filter((change) => typeof change === 'string'),
        tap((change) => {
          if (change && this._filterAdvertisingInterests(this.filteredAdvertisingInterests, change).length > 0) {
            this.filteredAdvertisingInterests = this._filterAdvertisingInterests(this.filteredAdvertisingInterests, change);
          } else {
            this.filteredAdvertisingInterests = this._getAdvertisingInterestDifference();
          }
        })
      )
      .subscribe();
  }

  private _filterAdvertisingInterests(advertisingInterests: IAdvertisingInterest[], displayDescription: string): IAdvertisingInterest[] {
    return advertisingInterests.filter((interest) =>
      interest.AdvertisingInterestDescription[this.translationService.locale].toLowerCase().includes(displayDescription?.toLowerCase())
    );
  }

  private _getAdvertisingInterestDifference(): IAdvertisingInterest[] {
    return this.autocompleteAdvertisingInterests.filter(
      (interest: IAdvertisingInterest) =>
        !this.selectedAdvertisingInterests.some((selectedInterest) => selectedInterest.AdvertisingInterestUUID === interest.AdvertisingInterestUUID)
    );
  }

  public removeAdvertisingInterest(advertisingInterest: IAdvertisingInterest): void {
    const advertisingInterestIndex = this.selectedAdvertisingInterests.indexOf(advertisingInterest);
    if (advertisingInterestIndex !== -1) {
      this.selectedAdvertisingInterests.splice(advertisingInterestIndex, 1);
      this.selectedAdvertisingInterestsChange.next(this.selectedAdvertisingInterests);
      this.filteredAdvertisingInterests.push(advertisingInterest);
      this.filteredAdvertisingInterests.sort((a, b) =>
        a.AdvertisingInterestDescription[this.translationService.locale].localeCompare(b.AdvertisingInterestDescription[this.translationService.locale])
      );
    }
  }

  public selectAdvertisingInterestFromAutocomplete(event: MatAutocompleteSelectedEvent): void {
    const selectedAdvertisingInterest: IAdvertisingInterest = this.filteredAdvertisingInterests.find(
      (interest: IAdvertisingInterest) => interest.AdvertisingInterestUUID === event.option.value
    );

    this._addSelectedAdvertisingInterest(selectedAdvertisingInterest);
    this.advertisingInterestsControl.reset(null);
    this.advertisingInterestInput.nativeElement.value = '';
  }

  public selectAdvertisingInterestFromInput(event: MatChipInputEvent): void {
    const selectedAdvertisingInterest: IAdvertisingInterest = this.filteredAdvertisingInterests.find(
      (interest: IAdvertisingInterest) => interest.AdvertisingInterestDescription[this.translationService.locale].toLowerCase() === event.value.toLowerCase()
    );

    if (selectedAdvertisingInterest) {
      this._addSelectedAdvertisingInterest(selectedAdvertisingInterest);
      this.advertisingInterestsControl.reset(null);
      this.advertisingInterestInput.nativeElement.value = '';
    }
  }

  private _addSelectedAdvertisingInterest(selectedAdvertisingInterest: IAdvertisingInterest): void {
    const selectedAdvertisingInterestIndex = this.filteredAdvertisingInterests.indexOf(selectedAdvertisingInterest);

    if (selectedAdvertisingInterestIndex !== -1) {
      this.filteredAdvertisingInterests.splice(selectedAdvertisingInterestIndex, 1);
      this.selectedAdvertisingInterests.push(selectedAdvertisingInterest);
      this.selectedAdvertisingInterestsChange.next(this.selectedAdvertisingInterests);
    }
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  public ngOnInit(): void {
    this.filteredAdvertisingInterests = this._getAdvertisingInterestDifference();
  }
}
