import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ICustomer, UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {}

  public saveInvoiceEMailAddresses(value: ICustomer): Observable<void> {
    let occurredError: HttpErrorResponse;

    return this._httpClient.patch<void>('api/customer', value).pipe(
      catchError((error) => {
        occurredError = error;
        return EMPTY;
      }),
      finalize(() => {
        // TODO Handle error correctly and tell the user about it
        if (occurredError) {
          console.error(occurredError);
        }

        this._userService.customer$.next(value);
      })
    );
  }
}
