import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCommonModule, MatLineModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseDialogModule } from '../base-dialog/base-dialog.module';
import { InvoiceEMailEditFormComponent } from './invoice-email-edit-form/invoice-email-edit-form.component';
import { InvoiceEmailProclamationComponent } from './invoice-email-proclamation/invoice-email-proclamation.component';

@NgModule({
  declarations: [InvoiceEmailProclamationComponent, InvoiceEMailEditFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCommonModule,
    MatListModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatLineModule,
    MatCardModule,
    MatCheckboxModule,
    BaseDialogModule,
  ],
  exports: [InvoiceEMailEditFormComponent, InvoiceEmailProclamationComponent],
})
export class InvoiceEmailProclamationModule {}
