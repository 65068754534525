<portal-base-dialog
  [header]="translationService.translations.common.PleaseAddCustomerInvoiceEMailAddress.toString()"
  (primaryAction)="saveEMailAddresses(invoiceEMailEditFormComponent.parentForm)"
  [icon]="null"
  #baseDialogComponent
>
  <div class="base-dialog-content">
    <portal-invoice-email-edit-form #invoiceEMailEditFormComponent [isInDialog]="true"></portal-invoice-email-edit-form>
    @if (!hideDismissNotificationCheckbox) {
      <mat-checkbox
        (change)="settingsService.setSetting('InvoiceEMailNotificationDismissed', $event.checked)"
        [checked]="(settingsService.settingsChange$ | async)?.InvoiceEMailNotificationDismissed"
        color="primary"
      >
        {{ translationService.translations.common.DontShowAgain }}
      </mat-checkbox>
    }
  </div>
</portal-base-dialog>
